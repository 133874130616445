import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/index/Index.vue')
  },
  {
    path: '/module/:assessId',
    name: 'Module',
    component: () => import('../views/module/Index.vue')
  },
  {
    path: '/topic/:assessId/:topicId',
    name: 'Topic',
    component: () => import('../views/topic/Index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/Index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
