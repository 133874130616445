import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission'

// createApp(App).use(store).use(router).mount('#app')
const app = createApp(App)
app.config.globalProperties.$filters = {
  fraction (value) {
    if (value === undefined) { return '' }
    const arr = value.toString().split('')
    const last = arr.pop()
    let str = arr.length > 0 ? arr.join('') : '0'
    if (last !== '0') {
      str += '.' + last
    }
    return str
  }
}
// this.$filters.fraction()

app.use(store).use(router).mount('#app')
