import router from './router'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
const loginRoutePath = '/login'
const defaultRoutePath = '/'

router.beforeEach((to, from, next) => {
  console.log('to.path', to.path)
  const token = storage.get(ACCESS_TOKEN)
  if (to.name !== 'Login' && !token) {
    next({ name: 'Login' })
    console.log('ddddd', token)
  } else {
    if (token && to.path === loginRoutePath) {
      console.log('ok')
      next({ path: defaultRoutePath })
    }
    // if (to.path === loginRoutePath) {
    //   next({ path: defaultRoutePath })
    // }
    next()
  }
})
